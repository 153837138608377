import { OfferEvaluation, OfferEvaluationData } from '@dims/components';
import { TenderData0219 } from '@/models';
import { BpqScores, OfferData0219 } from '@/models/Offer';

export type BPQCriteriaEnum =
 | 'Functionality'
 | 'Transition'
 | 'Organization'
 | 'ItSecurity'
 | 'GreenMeasures';

const categories: [BPQCriteriaEnum, string][] = [
  ['Functionality', 'Funktionalitet og brugervenlighed'],
  ['Transition', 'Transition - implementering og ophør af servicen'],
  ['Organization', 'Organisation og samarbejde'],
  ['ItSecurity', 'It-sikkerhed'],
  ['GreenMeasures', 'Grønne tiltag'],
];

export default {
  getEmptyCategories(tenderData: TenderData0219) {
    return categories
      .filter((c) => {
        const [key, _label] = c;
        return this.isScoreIncluded(tenderData, key);
      })
      .map((c) => {
        const [key, label] = c;
        return {
          key,
          label,
          score: 0,
          weight: 0,
          reason: '',
        };
      });
  },

  getCategories(tenderData: TenderData0219, offerEvaluation: OfferEvaluation) {
    return categories
      .filter((c) => {
        const [key, _label] = c;
        return this.isScoreIncluded(tenderData, key);
      })
      .map((c) => {
        const [key, label] = c;

        // Verbose version to avoid linting errors
        let score = 0;
        let reason = '';

        if (offerEvaluation.data) {
          const offerEvaluationElement = this.getOfferEvaluationElement(offerEvaluation.data, key);

          if (offerEvaluationElement) {
            score = offerEvaluationElement.score ?? 0;
            reason = offerEvaluationElement.reason ?? '';
          }
        }

        return {
          key,
          label,
          score,
          weight: this.getWeight(tenderData, key) ?? 0,
          reason,
        };
      });
  },

  criteriaStringToEnum(criteriaString: string): BPQCriteriaEnum | undefined {
    for (const [categoryEnum] of categories) {
      if (categoryEnum === criteriaString) {
        return categoryEnum;
      }
    }

    return undefined;
  },

  getOfferEvaluationElement(offerEvaluationData: OfferEvaluationData[], key: BPQCriteriaEnum) {
    return offerEvaluationData.find((o: OfferEvaluationData) => o.text === key);
  },

  getWeight(tenderData: TenderData0219, key: BPQCriteriaEnum): number | undefined {
    const propertyName: keyof TenderData0219 = `bpqCriteria${key}`;
    return tenderData[propertyName];
  },
  isScoreIncluded(tenderData: TenderData0219, key: BPQCriteriaEnum) {
    return (
      (tenderData.bpqCriteria && tenderData.bpqCriteria.includes(key))
      ?? (!tenderData.bpqCriteria && this.getWeight(tenderData, key) !== undefined)
    );
  },
  getScorePropertyName(key: BPQCriteriaEnum): keyof BpqScores {
    return `bpq${key}Score`;
  },
  getOfferScore(offerData: OfferData0219, key: BPQCriteriaEnum) {
    const propertyName = this.getScorePropertyName(key);
    return offerData[propertyName];
  },
  setOfferScore(offerData: OfferData0219, key: BPQCriteriaEnum, value: number) {
    const scorePropertyName = this.getScorePropertyName(key);
    // eslint-disable-next-line no-param-reassign
    offerData[scorePropertyName] = value;
  },
};
