<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    Du er nu oprettet som bruger i Kammeradvokatens risikovurderingssystem. Du får en e-mail med den adgangskode du skal
    bruge for at logge ind.
  </v-dialog>
  <HeaderSection header="Risikovurderingsværktøj" subHeader="" />
  <p>
    For at bruge Kammeradvokatens risikovurderingsværktøj skal du acceptere <a href="https://kammeradvokaten.dk/om-firmaet/saadan-haandterer-vi-dine-personoplysninger" target="_blank" rel="noopener noreferrer">brugerbetingelserne</a> og oprette en bruger. <br />
    Når du klikker på knappen "Tjek bruger" tjekker vi om du allerede er oprettet - ellers vil du blive oprettet med det samme.
  </p>
  <v-checkbox
    density="compact"
    v-model="termsAccepted"
  >
    <template #label>
      <div>
        Accepter brugerbetingelser
        <v-btn
          :disabled="!termsAccepted"
          @click="checkUser()"
          @click.stop
          class="ml-3"
        >
          Tjek bruger
        </v-btn>
      </div>
    </template>
  </v-checkbox>
  <p>
    Gå herefter videre til risikovurderingsværktøjet. Login med din
    email og den kode du fik sendt første gang du brugte
    risikovurderingsværktøjet.
  </p>
  <ArrowButton :disabled="!userChecked()" href="https://ski-docufy.documentdrafter.com" target="_blank" rel="noopener noreferrer"> Gå til risikovurdering</ArrowButton>
  <p>
    Dernæst får du adgang til et webbaseret spørgeskema kaldet
    'Risikovurdering'. Herunder finder du to spørgeskemaer - et
    vedr. informationssikkerhed og et. vedr. persondatasikkerhed. I
    hver af disse skal du svare på en række spørgsmål. Du kan
    desuden supplere med skriftlige begrundelser. Afslutningsvis
    genererer risikovurderingsværktøjet en skræddersyet rapport for
    hver af dine to risikovurderinger, i hvilke du finder
    risikomatricer og detaljerede beskrivelser af de anvendte
    risikovurderings-metoder. Når du er færdig med
    risikovurderingerne, skal du downloade rapporterne og gemme den som en del af din dokumentation for udbuddet.
  </p>
  <p>
    Se evt. Kammeradvokatens instruktionsvideo til risikovurderingen, som ligger på aftalesiden under
    <a href="https://www.ski.dk/udbud/se-udbud/?id=02190223" target="_blank" rel="noopener noreferrer">'Brug af aftalen'</a>
    og dertil findes et eksempel, ligeledes på aftalesiden, under punktet 'Aftaledokumenter - vejledning'.
  </p>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue';
import { HeaderSection, useStore, pskService, useBackend, ArrowButton } from '@dims/components';

const store = useStore();
const backend = useBackend();
const pskServiceProp = pskService(backend.userService);

const termsAcceptedValue = ref(false);
const userExists = ref(false);
const termsAccepted = computed({
  get() {
    return termsAcceptedValue.value;
  },
  set(value) {
    termsAcceptedValue.value = value;
    if (!termsAcceptedValue.value) { userExists.value = false; }
  },
});
const dialog = ref(false);

function userChecked() {
  return userExists.value;
}

async function checkUser() {
  const email = store.user?.username;
  if (email && termsAccepted.value) {
    const result = await pskServiceProp.checkUser(email);
    if (result === 'ExistingUser') {
      userExists.value = true;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (result === 'NewUser') {
      userExists.value = true;
      dialog.value = true;
    } else {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Fejl ved oprettelse af bruger: ${result}`);
    }
  }
}

</script>

<style scoped>
  p {
    font-size: 0.875rem;
  }

</style>
