<template>
  <div>
    <v-row>
      <v-col>
        <div class="d-flex align-center">
          <StepStatus :complete="selectedOffer?.riskAssessmentAccepted || unconditional" :label="4" />
          <h3 class="ma-2">Gennemfør risikovurdering</h3>
        </div>
        <ArrowButton
          :disabled="!isReadyForRiskAssessment"
          @click="getFiles(); securityassessmentDialog = true"
        >{{ riskAssessmentReadonly ? 'Se vurdering' : 'Foretag vurdering' }}
        </ArrowButton>
        <RiskAssessment
          v-if="selectedOffer && selectedProduct"
          v-model="securityassessmentDialog"
          :asset1="asset1"
          :asset2="asset2"
          :product="selectedProduct"
          :offer="selectedOffer"
          :readonly="riskAssessmentReadonly"
          :isBestPrice="isBestPrice"
          @directOffer="updateDirectOffer"
          @closeDialog="closeRiskDialog"
          @resetDirectOffer="resetDirectOffer"
        />
        <template v-if="unconditional">
          <div class="text-red mt-5">Risikovurderingen er ikke accepteret</div>
          <small v-if="riskResultComment">Kommentar: {{ riskResultComment }}</small>
        </template>
      </v-col>
    </v-row>
    <template v-if="!unconditional">
      <v-row>
        <v-col>
          <div class="d-flex align-center">
            <StepStatus :complete="selectedOffer?.requiredFilesState !== 'Unprepared'" :label="5" />
            <h3 class="ma-2">Færdiggør materiale</h3>
          </div>
          <v-dialog
            persistent
            v-model="prepareDocumentationDialog"
          >
            <template #activator="{ props }">
              <ArrowButton
                v-bind="props"
                :disabled="!selectedOffer || !selectedOffer.riskAssessmentAccepted || !isReadyForRiskAssessment"
              >{{ riskAssessmentReadonly ? 'Se dokumenter' : 'Udfyld dokumenter' }}
              </ArrowButton>
            </template>
            <PrepareDocumentation
              :tender="tender"
              :readonly="!isEvaluation || !selectedOffer || unconditional || !selectedOffer.riskAssessmentAccepted || !isReadyForRiskAssessment"
              @closeDialog="prepareDocumentationDialog = false"
              @isCompleteChanged="isDocumentationCompleteChanged($event)"
            />
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="d-flex align-center">
            <StepStatus :complete="isReadOnly" :label="6" />
            <h3 class="ma-2">Tildel</h3>
          </div>
          <!-- award -->
          <TenderTerminationDirectAwardDialog
            v-if="selectedOffer"
            :tender="tender"
            :disabled="!tenderTerminationButtonEnabled"
            :conditionalOffers="[selectedOffer]"
            :unconditionalOffers="[]"
          />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useBackend, useDirectAwardStore, Asset, PrepareDocumentation, TenderStateEnum } from '@dims/components';
import RiskAssessment from './RiskAssessment.vue';
import { Tender0219 } from '@/models/Tender';
import TenderTerminationDirectAwardDialog from './TenderTerminationDirectAwardDialog.vue';
import { Product0219 } from '@/models/PIM/Product0219';
import { ExtendedVariant } from '@/models/PIM/Variant0219';
import { DirectOffer0219 } from '@/models/DirectOffer';

const { tender, selectedOffer = null, selectedVariant, selectedProduct, isBestPrice = false, isReadyForRiskAssessment = false } = defineProps<{
  tender: Tender0219,
  selectedOffer?: DirectOffer0219 | null,
  selectedVariant: ExtendedVariant | null,
  selectedProduct: Product0219 | null,
  isBestPrice?: boolean,
  isReadyForRiskAssessment?: boolean,
}>();
const backend = useBackend();
const directAwardStore = useDirectAwardStore();
const securityassessmentDialog = ref(false);
const asset1 = ref<Asset | null>(null);
const asset2 = ref<Asset | null>(null);
const prepareDocumentationDialog = ref(false);

const tenderTerminationButtonEnabled = computed(() => {
  const riskAssessmentAccepted = selectedOffer?.riskAssessmentAccepted;
  const requiredFilesState = selectedOffer?.requiredFilesState;

  return (riskAssessmentAccepted && requiredFilesState === 'Prepared') ?? false;
});

const isReadOnly = computed(() => tender.state !== TenderStateEnum.Evaluate);

const isEvaluation = computed(() => tender.state === TenderStateEnum.Evaluate);

const riskAssessmentReadonly = computed(() => !isEvaluation.value);

const riskResultComment = computed(() => selectedOffer?.riskResultComment);

const unconditional = computed(() => selectedOffer?.riskAssessmentAccepted === false);

function closeRiskDialog() {
  securityassessmentDialog.value = false;
}

function updateDirectOffer(event: DirectOffer0219) {
  if (selectedOffer) {
    selectedOffer.artifacts = event.artifacts;
    selectedOffer.riskAssessmentAccepted = event.riskAssessmentAccepted;
    selectedOffer.riskResultComment = event.riskResultComment;
  }
}

async function isDocumentationCompleteChanged(isComplete: boolean) {
  const newState = isComplete ? 'Prepared' : 'Unprepared';
  if (selectedOffer && selectedOffer.requiredFilesState !== newState) {
    await backend.directOfferService.setRequiredFilesState(selectedOffer.id, newState);
    selectedOffer.requiredFilesState = newState;
    directAwardStore.setDocumentationPrepared(isComplete);
  }
}

async function getFiles() {
  if (selectedVariant) {
    try {
      asset1.value = await backend.pimDataService.fetchAsset(selectedProduct?.securityLevelQuestionnaireITAttachment_0219Publ ?? '');
      asset2.value = await backend.pimDataService.fetchAsset(selectedProduct?.securityLevelQuestionnaireTransferAttachment_0219Publ ?? '');
    } catch (error) {
      throw new Error('Det lykkedes ikke at hente data fra PIM. Service er måske nede, prøv igen senere.');
    }
  }
}

// Roll back changes to the direct offer if user cancels the risk assessment dialog
// Currently not working with artifacts
function resetDirectOffer(directOffer: DirectOffer0219) {
  if (selectedOffer) {
    selectedOffer.riskAssessmentAccepted = directOffer.riskAssessmentAccepted;
    selectedOffer.riskResultComment = directOffer.riskResultComment;
    selectedOffer.riskThirdCountry = directOffer.riskThirdCountry;
    selectedOffer.riskSecurityLevel = directOffer.riskSecurityLevel;
  }
}

</script>
