import { Calculator, Offer, TenderStateEnum, Tender, toCurrencyFormatDK2, OfferEvaluation, OfferEvaluationData } from '@dims/components';
import { Offer0219 } from '@/models/Offer';
import { DraftTender0219, Tender0219 } from '@/models/Tender';
import { BPQCriteriaEnum } from '../services/bpqCriteria';

export const calculatePointPrice = (offer: Offer0219, _tender: Tender0219, _totalBpqPoints: number): number => {
  const result = (offer.data.tcoCalculationResult?.tcoPrice ?? 0) / _totalBpqPoints;
  return result;
};

const checkValidScores = (offerEvaluation: OfferEvaluation): boolean => {
  if (offerEvaluation.data) {
    if (offerEvaluation.data.some((data) => data.score)) {
      return true;
    }
  }

  return false;
};

export const calculateBPQRatioScore = (offer: Offer0219, tender: Tender0219, offerEvaluation?: OfferEvaluation): number => {
  const td = tender.data;

  const functionalityPercentage = (td.bpqCriteriaFunctionality ?? 0);
  const greenMeasuresPercentage = (td.bpqCriteriaGreenMeasures ?? 0);
  const itSecurityPercentage = (td.bpqCriteriaItSecurity ?? 0);
  const organizationPercentage = (td.bpqCriteriaOrganization ?? 0);
  const transitionPercentage = (td.bpqCriteriaTransition ?? 0);

  if (offerEvaluation && offerEvaluation.data && checkValidScores(offerEvaluation)) {
    const totalScore = getOfferEvaluationElementScore(offerEvaluation.data, 'Functionality') * functionalityPercentage
    + getOfferEvaluationElementScore(offerEvaluation.data, 'GreenMeasures') * greenMeasuresPercentage
    + getOfferEvaluationElementScore(offerEvaluation.data, 'ItSecurity') * itSecurityPercentage
    + getOfferEvaluationElementScore(offerEvaluation.data, 'Organization') * organizationPercentage
    + getOfferEvaluationElementScore(offerEvaluation.data, 'Transition') * transitionPercentage;

    return Number(totalScore.toFixed(1));
  }

  return 0;
};

function getOfferEvaluationElementScore(offerEvaluationData: OfferEvaluationData[], key: BPQCriteriaEnum) {
  const offerEvaluationElement = offerEvaluationData.find((o: OfferEvaluationData) => o.text === key);

  if (offerEvaluationElement !== undefined) {
    return offerEvaluationElement.score ?? 0;
  }

  return 0;
}

export const convertDecimalSeperators = (value: string): string => value.toString().replace('.', ',');

export const convertDecimalSeperator = (value: number): string => convertDecimalSeperators(value.toString());

export const calculateTotalPricePerPoint = (offer: Offer0219, tender: Tender0219, offerEvaluation?: OfferEvaluation): number => {
  const bpqRatioScore = calculateBPQRatioScore(offer, tender, offerEvaluation);
  const pointPrice = calculatePointPrice(offer, tender, bpqRatioScore);

  return Number(pointPrice.toFixed(2));
};

export const tenderStub: DraftTender0219 = {
  data: {
    requestForOfferDone: false,
    specificationDone: false,
    deliveryAgreementDone: false,
  },
  agreementName: 'dummy',
  state: TenderStateEnum.Prepare,
};

/* Expose the agreement specific calculations in a generic way to be used by shared components */
export class Calculator0219 implements Calculator {
  isScoreBelowConditionalThreshold_BackendCalculation(_offer: Offer0219): boolean {
    // For 02.19 this will always return false, since this is only calculated backend
    return false;
  }

  isScoreBelowConditionalThreshold_FrontendCalculation(offer: Offer0219, tender: Tender0219): boolean {
    if (offer.data.bpqPointScore != null
      && offer.data.bpqPointScore > 0
      && tender.data.bpqPointScoreMinimum != null
      && offer.data.bpqPointScore < tender.data.bpqPointScoreMinimum) {
      return true;
    }

    return false;
  }

  sortByCustomRanking(offers: Offer0219[]): Offer[] {
    return offers.sort(
      (a, b) => (a.data.customRanking ?? 0) - (b.data.customRanking ?? 0),
    );
  }

  calculateRelativeScore(offer: Offer0219, tender: Tender0219, _conditionalOffers: Offer[], offerEvaluation?: OfferEvaluation) {
    return calculateBPQRatioScore(offer, tender, offerEvaluation);
  }

  calculatePrice(offer: Offer) {
    return (offer as Offer0219).data.tcoCalculationResult?.tcoPrice ?? 0;
  }

  sortByPrice(offers: Offer[]) {
    return offers.sort(
      // lowest price first, so a - b
      (a, b) => this.calculatePrice(a) - this.calculatePrice(b),
    );
  }

  sortBPQBestFirst(offers: Offer[], tender: Tender, offersEvaluations: OfferEvaluation[]) {
    return offers.sort(
      // should be negative if a is the best offer, since we want best first
      // since the lowest price is best, result is price(a) - price(b)
      (a, b) => calculateTotalPricePerPoint(a, tender, offersEvaluations.find((oe) => oe.offerId === a.id))
        - calculateTotalPricePerPoint(b, tender, offersEvaluations.find((oe) => oe.offerId === b.id)),
    );
  }

  contractSum(offer: Offer) {
    return offer.contractSum ?? 0;
  }

  /** Shown in 'Samlede omkostninger' column for non-BPQ evaluation  */
  totalCost(offer: Offer0219) {
    return offer.data.tcoCalculationResult?.tcoPrice ?? 0;
  }

  /** Shown in 'Samlet score' column for BPQ evaluation
   * should represent the factor used for ordering the offers */
  bpqScoreText(offer: Offer0219, tender: Tender, _conditionalOffers: Offer[], offerEvaluation?: OfferEvaluation) {
    const pricePrPoint = calculateTotalPricePerPoint(offer, tender, offerEvaluation);
    return `${toCurrencyFormatDK2(pricePrPoint)} / point`;
  }
}

export function getCalculator() {
  return new Calculator0219();
}
