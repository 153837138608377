<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      Du er nu oprettet som bruger i Kammeradvokatens risikovurderingssystem. Du får en e-mail med den adgangskode du skal
      bruge for at logge ind.
    </v-dialog>
    <v-dialog v-model="model" max-width="1500" persistent>
      <v-container fluid class="bg-canvas overflow-y-auto">
        <v-row>
          <v-col>
            <v-card style="overflow:hidden">
              <v-card-title>
                <h2 class="mt-2">Risikovurdering og vurdering af overførsel til tredjeland</h2>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="9">
                    <p>
                      Før du tildeler, bør du lave to risikovurderinger samt
                      vurdere overførsel til tredjeland for at afgøre, om servicen
                      lever op til dine krav.
                    </p>
                    <p>
                      Du bør lave to risikovurderinger, af henholdsvis
                      informationssikkerhed samt persondatasikkerhed, for at
                      afklare, om den pågældende service/servicevariant overholder
                      dine sikkerhedskrav. Vurderingen foretages på baggrund af
                      leverandørens besvarelse i bilag C.1.
                    </p>
                    <p>
                      Til dette kan du vælge
                      at benytte Kammeradvokatens risikovurderingsværktøj, som SKI
                      stiller til rådighed ved nedenstående link. Du kan også
                      vælge at benytte en anden risikovurdering, men for at komme
                      videre til tildeling, skal du uploade dine
                      risikovurderingsrapporter nedenfor, som da bliver en del af
                      din dokumentpakke vedr. denne tildeling.
                    </p>
                    <p>
                      Du indtaster
                      resultatet af den vurdering nederst i dette vindue. Såfremt
                      dit krav til sikkerhedsniveau ikke bliver mødt, kan du ikke
                      tildele til den pågældende leverandør. Du skal i stedet
                      vurdere servicen med den næstlaveste pris.
                    </p>
                    <p>
                      Hvis der sker overførsel af personoplysninger til
                      tredjeland, skal du vurdere om overførselen til tredjeland
                      er lovlig. Vurderingen foretages på baggrund af
                      leverandørens besvarelse i bilag C.2. Du kan uploade et
                      dokument som dokumentation for vurderingen nedenfor. Du
                      indtaster svaret nederst i dette vindue, og såfremt
                      overførslen ikke er lovlig kan du ikke tildele til den
                      pågældende leverandør. Du skal i stedet vurdere servicen med
                      den næstlaveste pris.
                    </p>
                    <p>
                      Bemærk risikovurderingsrapporterne samt anden dokumentation
                      bliver ikke delt med leverandøren, men bliver en del af dine
                      egne dokumenter sammen med fx interne noter (hvis du har
                      benyttet denne funktion undervejs i kravspecifikationen).
                    </p>
                  </v-col>
                  <v-col>
                    <WinnerTag
                      v-if="isBestPrice"
                      :isWinner="readonly"
                      :avatarSize="80"
                      :iconSize="35"
                      class="winner-tag"
                      tagClass="pr-2 pt-2"
                    />
                    <v-card color="canvas">
                      <v-card-title>
                        {{ product.name }}
                      </v-card-title>
                      <v-card-text>
                        <div class="text-subtitle-1">{{ product.supplier?.name }}</div>
                        Sikkerhedsniveau: {{ product.securityLevel_0219 }}
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-3">
          <v-col>
            <v-card>
              <v-card-title>
                Leverandørens dokumenter
              </v-card-title>
              <v-card-text>
                <p>
                  Du bør læse leverandørens besvarelse af bilag C.1 førend du
                  laver dine risikovurderinger, og C.2 førend du vurderer
                  overførsel til tredjeland. Hent og læs dem igennem, inden du
                  går videre:
                </p>

                <v-card color="canvas" flat>
                  <v-card-text>
                    <v-row v-if="asset1">
                      <v-col>Dokument vedrørende IT-sikkerhed (bilag C.1)</v-col>
                      <v-col cols="auto">
                        <span style="display: content">
                          <v-progress-circular
                            class="downloadspinner"
                            v-if="isDownLoadingFile1"
                            size="20"
                            indeterminate
                          />
                          <button
                            type="button"
                            @click="isDownLoadingFile1 = true; download(asset1);"
                            class="link"
                          >
                            <v-icon class="cursor-pointer mr-1">mdi-file-download-outline</v-icon>
                            <slot>{{ asset1?.name }}</slot>
                          </button>
                        </span>
                      </v-col>
                    </v-row>
                    <v-row v-if="asset2">
                      <v-col>Dokument vedrørende tredjelandsoverførsler (bilag C.2)</v-col>
                      <v-col cols="auto">
                        <span style="display: content">
                          <v-progress-circular
                            class="downloadspinner"
                            v-if="isDownLoadingFile2"
                            size="20"
                            indeterminate
                          />
                          <button
                            type="button"
                            @click="isDownLoadingFile2 = true; download(asset2);"
                            class="link"
                          >
                            <v-icon class="cursor-pointer mr-1">mdi-file-download-outline</v-icon>
                            <slot>{{ asset2?.name }}</slot>
                          </button>
                        </span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-3">
          <v-col>
            <v-card>
              <v-card-title>
                Kammeradvokatens Risikovurderingsværktøj
              </v-card-title>
              <v-card-subtitle>
                For at bruge Kammeradvokatens risikovurderingsværktøj skal du acceptere <a href="https://kammeradvokaten.dk/om-firmaet/saadan-haandterer-vi-dine-personoplysninger" target="_blank" rel="noopener noreferrer">brugerbetingelserne</a> og oprette en bruger. <br />
                Når du klikker på knappen "Tjek bruger" tjekker vi om du allerede er oprettet - ellers vil du blive oprettet med det samme.
              </v-card-subtitle>
              <v-card-text>
                <v-checkbox
                  :disabled="readonly"
                  v-model="termsAccepted"
                >
                  <template #label>
                    <div>
                      Accepter brugerbetingelser
                      <v-btn
                        :disabled="!termsAccepted"
                        @click="checkUser()"
                        @click.stop
                        class="ml-3"
                      >
                        Tjek bruger
                      </v-btn>
                    </div>
                  </template>
                </v-checkbox>
                <p>
                  Gå herefter videre til risikovurderingsværktøjet. Login med din
                  email og den kode du fik sendt første gang du brugte
                  risikovurderingsværktøjet.
                </p>
                <p>
                  Dernæst får du adgang til et webbaseret spørgeskema kaldet
                  'Risikovurdering'. Herunder finder du to spørgeskemaer - et
                  vedr. informationssikkerhed og et. vedr. persondatasikkerhed. I
                  hver af disse skal du svare på en række spørgsmål. Du kan
                  desuden supplere med skriftlige begrundelser. Afslutningsvis
                  genererer risikovurderingsværktøjet en skræddersyet rapport for
                  hver af dine to risikovurderinger, i hvilke du finder
                  risikomatricer og detaljerede beskrivelser af de anvendte
                  risikovurderings-metoder. Når du er færdig med
                  risikovurderingerne, skal du uploade rapporterne nedenfor, og de
                  bliver således en del af din dokumentpakke for tildelingen.
                </p>
                <p>
                  Se evt. Kammeradvokatens instruktionsvideo til risikovurderingen, som ligger på aftalesiden under
                  <a href="https://www.ski.dk/udbud/se-udbud/?id=02190223" target="_blank" rel="noopener noreferrer">'Brug af aftalen'</a>
                  og dertil findes et eksempel, ligeledes på aftalesiden, under punktet 'Aftaledokumenter - vejledning'.
                </p>
                <v-card-actions>
                  <v-btn :disabled="!userChecked()" href="https://ski-docufy.documentdrafter.com" target="_blank" rel="noopener noreferrer"> Risikovurdering</v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-3">
          <v-col>
            <v-card>
              <v-card-title>
                Risikovurderingsrapporter samt anden dokumentation
              </v-card-title>
              <v-card-text v-if="!readonly">
                Når du er færdig med risikovurderingerne skal du uploade
                rapporterne her. Du kan uploade dokumentation for din vurdering af
                overførsel til tredjeland, hvis relevant. Bemærk det er kun muligt
                at uploade én fil, hvorfor vi anbefaler, at du samler dine
                risikovurderingsrapporter og evt. dokumentation for vurdering af
                overførsel til tredjeland i en zip-fil.
              </v-card-text>
              <v-card-text>
                <v-file-input
                  v-model="selectedFile"
                  :disabled="readonly"
                  density="compact"
                  label="Vedhæft fil"
                  @change="uploadFile"
                  variant="outlined"
                  :loading="isLoading"
                ></v-file-input>
                <div v-if="artifacts?.length">
                  <div class="text-body-2">Vedhæftet fil:</div>
                  <v-list density="compact">
                    <v-list-item
                      v-for="(file, i) in artifacts"
                      :key="i"
                      class="file-download-box mb-2"
                      :title="file.displayName"
                    >
                      <template #prepend>
                        <v-avatar color="canvas-darken-3">
                          <v-icon icon="mdi-file-outline" />
                        </v-avatar>
                      </template>

                      <template #append>
                        <div>
                          <v-btn
                            class="mr-4"
                            @click="downloadFileAsync()"
                            icon="mdi-download-outline"
                          />
                          <v-btn
                            v-if="!readonly"
                            icon="mdi-trash-can-outline"
                            @click="deleteFile()"
                          />
                        </div>
                      </template>
                    </v-list-item>
                  </v-list>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-3">
          <v-col>
            <v-card>
              <v-card-title>
                Opfylder leverandøren dine sikkerhedskrav?
              </v-card-title>
              <v-card-text>
                Du fastlægger dit samlede relevante sikkerhedsniveau, på
                baggrund af de to risikovurderingsrapporter.
                Sikkerhedsniveauet fastlægges ud fra den højeste
                risikovurdering for hhv. informationssikkerhed og
                persondatasikkerhed. Dvs. hvis den ene rapport konkluderer Høj
                og den anden Mellem, vil dit sikkerhedsniveau være: Høj.
                Leverandørens sikkerhedsniveau er angivet i boksen øverst til
                højre i dette vindue.
              </v-card-text>
              <v-card-text>
                <v-radio-group
                  v-model="offer.riskSecurityLevel"
                  density="compact"
                  class="mt-n3"
                  :disabled="!readyForAccept || readonly"
                >
                  <v-radio label="Ja" :value="true" />
                  <v-radio label="Nej" :value="false" />
                </v-radio-group>
                <div class="mb-1">Vurdering af overførsel til tredjeland:</div>
                <v-radio-group
                  v-model="offer.riskThirdCountry"
                  density="compact"
                  :disabled="!readyForAccept || readonly"
                >
                  <v-radio label="Nej, der sker ikke overførsel til tredjeland" value="NoTransferToThirdCountry" />
                  <v-radio label="Ja, der sker overførsel til tredjelande og overførslen er lovlig" value="LegalTransferToThirdCountry" />
                  <v-radio label="Ja, der sker overførsel til tredjelande men overførsel er ikke lovlig" value="IllegalTransferToThirdCountry" />
                </v-radio-group>
                <template v-if="unconditional">
                  <p>
                    Beskriv her det krævede sikkerhedsniveau i henhold til dine
                    risikovurderinger ift. leverandørens tilbudte
                    sikkerhedsniveau, og/eller hvis du vurderer at overførsel
                    til tredjelande ikke sker er lovligt.
                  </p>
                  <v-textarea
                    v-model="offer.riskResultComment"
                    :disabled="readonly"
                    label="Kommentar til vurdering"
                    variant="outlined"
                    rows="2"
                    density="compact"
                  />
                </template>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-3">
          <v-col></v-col>
          <v-col cols="auto">
            <v-btn
              v-if="readonly"
              class="primary-button"
              @click="close()"
            >
              Luk
            </v-btn>
            <v-btn
              v-if="!readonly"
              class="secondary-button"
              @click="cancel()"
            >
              Annuller
            </v-btn>
            <v-btn
              v-if="!readonly"
              :disabled="!readyForSave"
              class="primary-button"
              @click="updateOffer()"
            >
              Gem
            </v-btn>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import { useBackend, Asset, pskService, useStore, useDirectAwardStore, downloadFile } from '@dims/components';
import { ref, computed, watch } from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { Product0219 } from '@/models/PIM/Product0219';
import WinnerTag from './WinnerTag.vue';
import { DirectOffer0219 } from '@/models/DirectOffer';

const emit = defineEmits<{
  resetDirectOffer: [DirectOffer0219],
  closeDialog: [],
  directOffer: [DirectOffer0219]
  }>();
const { asset1, asset2, offer, readonly = false, isBestPrice = false } = defineProps<{
  asset1: Asset | null,
  asset2: Asset | null,
  product: Product0219,
  offer: DirectOffer0219,
  readonly?: boolean,
  isBestPrice?: boolean,
}>();
const model = defineModel<boolean>();
const store = useStore();
const backend = useBackend();
const directAwardStore = useDirectAwardStore();
const pskServiceProp = pskService(backend.userService);
const termsAcceptedValue = ref(false);
const userExists = ref(false);
const termsAccepted = computed({
  get() {
    return termsAcceptedValue.value;
  },
  set(value) {
    termsAcceptedValue.value = value;
    if (!termsAcceptedValue.value) { userExists.value = false; }
  },
});
const dialog = ref(false);
const templateKey = 'riskAssessment';
const templateArtifactKey = 'report';
const isLoading = ref(false);
const isDownLoadingFile1 = ref(false);
const isDownLoadingFile2 = ref(false);
const selectedFile = ref<File | null>(null);
const clone = ref<DirectOffer0219 | null>(null);

watch(
  model,
  () => {
    if (model.value) {
      // The direct offer clone is used for resetting the offer if the dialog is closed without saving
      clone.value = cloneDeep(offer);
    } else {
      selectedFile.value = null;
    }
  },
);

const artifacts = computed(() => offer.artifacts?.filter((x) => x.templateArtifactKey === templateArtifactKey && x.templateKey === templateKey) ?? []);

const readyForAccept = computed((): boolean => artifacts.value.length > 0);

const readyForSave = computed((): boolean => {
  const isRiskSecurityValid = typeof offer.riskSecurityLevel === 'boolean';
  const isRiskResultFileValid = artifacts.value.length > 0;
  const isRiskThirdCountryValid = offer.riskThirdCountry !== undefined;
  const isRiskResultCommentValid = (offer.riskSecurityLevel === true
      && offer.riskThirdCountry !== 'IllegalTransferToThirdCountry')
      || !!offer.riskResultComment;
  return isRiskSecurityValid
      && isRiskResultFileValid
      && isRiskThirdCountryValid
      && isRiskResultCommentValid;
});

const accepted = computed(() => offer.riskSecurityLevel === true && offer.riskThirdCountry !== 'IllegalTransferToThirdCountry');

const unconditional = computed(() => offer.riskSecurityLevel === false || offer.riskThirdCountry === 'IllegalTransferToThirdCountry');

async function uploadFile() {
  if (!selectedFile.value) {
    return;
  }
  try {
    isLoading.value = true;
    const directOffer = await backend.documentService.uploadDirectOfferArtifact<DirectOffer0219>(
      offer,
      templateKey,
      templateArtifactKey,
      selectedFile.value,
    );
    emit('directOffer', directOffer);
  } catch (error) {
    console.error(error);
    store.setSnackbarText('Kan ikke uploade dokument.');
  } finally {
    selectedFile.value = null;
    isLoading.value = false;
  }
}

async function deleteFile() {
  const directOffer = await directAwardStore.deleteDirectOfferArtifactAction<DirectOffer0219>(
    {
      directOffer: offer,
      templateKey,
      templateArtifactKey,
    },
  );
  emit('directOffer', directOffer);
}

function userChecked() {
  return userExists.value;
}

async function checkUser() {
  const email = store.user?.username;
  if (email && termsAccepted.value) {
    const result = await pskServiceProp.checkUser(email);
    if (result === 'ExistingUser') {
      userExists.value = true;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (result === 'NewUser') {
      userExists.value = true;
      dialog.value = true;
    } else {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Fejl ved oprettelse af bruger: ${result}`);
    }
  }
}

async function updateOffer() {
  let changed = false;
  if (accepted.value) {
    offer.riskResultComment = undefined;
    directAwardStore.setRiskAssesmentCompleted(accepted.value);
    directAwardStore.setEvaluationCompleted(accepted.value);
    changed = true;
  }
  try {
    const newOffer = {
      riskAssessmentAccepted: accepted.value,
      riskResultComment: offer.riskResultComment ?? undefined,
      riskSecurityLevel: offer.riskSecurityLevel,
      riskThirdCountry: offer.riskThirdCountry,
    };
    const updatedOffer = await backend.directOfferService.updateOffer(offer, newOffer);
    emit('directOffer', updatedOffer);
    close();
  } catch (e) {
    store.setSnackbarText('Kan ikke opdatere tilbud. Prøv igen');
    if (changed) {
      directAwardStore.setRiskAssesmentCompleted(!accepted.value);
      directAwardStore.setEvaluationCompleted(!accepted.value);
    }
  }
}

function close() {
  emit('closeDialog');
}

function cancel() {
  if (clone.value) {
    emit('resetDirectOffer', clone.value);
  }
  close();
}

async function downloadFileAsync() {
  const downloadedFile = await backend.documentService.downloadDirectOfferArtifact(offer, templateKey, templateArtifactKey);
  // #16322 temporary workaround
  downloadFile(downloadedFile.data, downloadedFile.fileName);
}

function download(asset: Asset) {
  if (asset.url) {
    window.open(asset.url);
    isDownLoadingFile1.value = false;
    isDownLoadingFile2.value = false;
  } else {
    store.setSnackbarText('Dokument mangler');
  }
}

</script>

<style scoped>
h2,
h3 {
  text-transform: uppercase;
}

th { text-align: left;
  padding-left: 10px;}

.list-item {
  background: rgb(var(--v-theme-canvas)) !important;
  border: 1px solid rgb(var(--v-skiGrey-darken-1));
}

.winner-tag {
  font-size: 55px;
  position: absolute;
  top: -15px;
  right: -20px;
}

.listitem {
  cursor: pointer;
}

.downloadspinner{margin-right: 5px;}

</style>
