<template>
  <v-card
    :loading="isLoading"
    :color="color"
    class="fill-height d-flex flex-column"
  >
    <v-card-title class="pb-2">
      <v-row no-gutters>
        <v-col cols="auto" class="mr-2">
          <v-icon>{{ icon }}</v-icon>
        </v-col>
        <v-col>
          <div>{{ product.name }}</div>
          <div class="text-caption">{{ product.supplier?.name }}</div>
        </v-col>
        <v-col cols="auto">
          <slot></slot>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider class="my-0"></v-divider>
    <v-card-text>
      <div v-if="isProductDeselected" class="font-italic">{{ deselectionReason }}</div>
      <div v-else>
        <CartDetails
          :selected="uniqueVariants.map((s) => s.serviceVariantName_0219 ?? 'ukendt')"
          :isLoading="isLoading"
          @openDialog="openDialog"
        >
          Servicevarianter</CartDetails>
        <CartDetails
          v-if="totalFunctionalAddOns.length > 0"
          :complete="isFunctionalAddOnsCompleted"
          :selected="selectedFunctionalAddOns.map((s) => (s.name ?? 'ukendt'))"
          :deselected="deselectedFunctionalAddOns.map((s) => (s.name ?? 'ukendt'))"
          :isLoading="isLoading"
          @openDialog="openDialog"
        >
          Funktionelle tillægsydelser
        </CartDetails>
        <CartDetails
          v-if="totalSupplierSpecificIntegrations.length > 0"
          :complete="isSupplierSpecificIntegrationsCompleted"
          :selected="selectedSupplierSpecificIntegrations.map((s) => (s.name ?? 'ukendt'))"
          :deselected="deselectedSupplierSpecificIntegrations.map((s) => (s.name ?? 'ukendt'))"
          :isLoading="isLoading"
          @openDialog="openDialog"
        >
          Leverandørspecifikke integrationer</CartDetails>
      </div>
    </v-card-text>
    <v-spacer></v-spacer>
    <v-card-actions class="d-flex justify-center ma-2">
      <v-btn
        v-if="product.state === 'Active'"
        :disabled="isLoading"
        class="primary-button-mini mr-2"
        @click="onAccept()"
      >
        Tilvælg
      </v-btn>
      <v-btn
        v-if="product.state === 'Rejected'"
        :disabled="isLoading"
        class="secondary-button-mini mr-2"
        @click="onSave()"
      >
        Fortryd
      </v-btn>
      <DeselectServiceDialog
        v-if="product.state !== 'Rejected'"
        :isLoading="isLoading"
        @reject="onReject"
      />
    </v-card-actions>
  </v-card>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { Product0219 } from '@/models/PIM/Product0219';
import { pimIntegrationHelper } from '@/services/pimIntegrationHelper';
import { Variant0219 } from '@/models/PIM/Variant0219';
import { FunctionalAddOn0219 } from '@/models/PIM/FunctionalAddOn0219';
import { SupplierSpecificIntegration0219 } from '@/models/PIM/SupplierSpecificIntegration0219';
import CartDetails from './CartDetails.vue';
import DeselectServiceDialog from './DeselectServiceDialog.vue';

const emit = defineEmits<{
  openDialog: [Product0219],
  reject: [Variant0219[], Product0219, string],
  accept: [Variant0219[], Product0219],
  save: [Variant0219[], Product0219],
}>();
const { product, variants, functionalAddOns, supplierSpecificIntegrations, isLoading = false } = defineProps<{
  product: Product0219,
  variants: Variant0219[],
  functionalAddOns: FunctionalAddOn0219[],
  supplierSpecificIntegrations: SupplierSpecificIntegration0219[],
  isLoading?: boolean,
}>();

const getVariantsByProduct = computed(() => pimIntegrationHelper.getVariantsByProduct(variants, product));

const isProductDeselected = computed(() => getVariantsByProduct.value.every((v) => v.deselectionReason));

const uniqueVariants = computed(() => pimIntegrationHelper.uniqueVariants(variants, product));

const totalFunctionalAddOns = computed(() => pimIntegrationHelper.totalFunctionalAddOns(functionalAddOns, product));

const isFunctionalAddOnsCompleted = computed(() => pimIntegrationHelper.isFunctionalAddOnsCompleted(variants, functionalAddOns, product));

const selectedFunctionalAddOns = computed(() => pimIntegrationHelper.selectedFunctionalAddOns(variants, product));

const deselectedFunctionalAddOns = computed(() => pimIntegrationHelper.deselectedFunctionalAddOns(variants, product));

const totalSupplierSpecificIntegrations = computed(() => pimIntegrationHelper.totalSupplierSpecificIntegrations(supplierSpecificIntegrations, product));

const isSupplierSpecificIntegrationsCompleted = computed(() => pimIntegrationHelper.isSupplierSpecificIntegrationsCompleted(variants, supplierSpecificIntegrations, product));

const selectedSupplierSpecificIntegrations = computed(() => pimIntegrationHelper.selectedSupplierSpecificIntegrations(variants, product));

const deselectedSupplierSpecificIntegrations = computed(() => pimIntegrationHelper.deselectedSupplierSpecificIntegrations(variants, product));

const color = computed(() => {
  switch (product.state) {
    case 'Accepted':
      return '#D4E3DC';
    case 'Rejected':
      return 'colocationSelected';
    default:
      return '';
  }
});

const icon = computed(() => {
  switch (product.state) {
    case 'Accepted':
      return 'mdi-check-circle-outline';
    case 'Rejected':
      return 'mdi-close-circle-outline';
    default:
      return 'mdi-pencil-circle-outline';
  }
});

const deselectionReason = computed(() => pimIntegrationHelper.deselectionReason(getVariantsByProduct.value, product));

function onSave() {
  emit('save', getVariantsByProduct.value, product);
}

function onAccept() {
  emit('accept', getVariantsByProduct.value, product);
}

function onReject(note: string) {
  emit('reject', getVariantsByProduct.value, product, note);
}

function openDialog() {
  emit('openDialog', product);
}

</script>
