<template>
  <v-container fluid>
    <TenderMaterialIntroText v-if="!readonly" :tender="tender">
    </TenderMaterialIntroText>
    <div v-if="readonly" :tender="tender">
      <p class="mt-4">
        Du har nu færdiggjort kravspecifikationen og kan gå til fanen 'Evaluering'. <br />
        Det vil være muligt at vende tilbage og redigerer i kravene, hvis du får behov for dette.
      </p>
      <p>
        <strong>Bemærk</strong> følgende dokumenter er ikke endeligt udfyldt, før evalueringen er gennemført og vinderen er identificeret: <br />
        Bilag E Leveringskontrakt, Bilag E.3 Leverancebeskrivelse, Bilag E.6 Implementering, Bilag E.8 Databehandleraftale samt Tildelingsbrevet.
      </p>
      <p>
        Du kan derfor se de foreløbige versioner her - og de endelige, når du er klar til at tildele under 'Evaluering'.
      </p>
      <p>
        Har du brugt funktionen 'interne noter' samt uploadet en behovsopgørelse kan du se disse når du når til 'Afslutning' og trykker 'Hent udbudsrapport'
      </p>
    </div>
    <TenderMaterialTableV2
      :documentList="documentList"
      :uploadModel="uploadModel"
      :tender="tender"
      :readonly="false"
      @setDocumentDoneStatus="setDocumentDoneStatus"
      @removeFile="removeFile"
      @edit="onEdit($event)"
      @preview="onPreview($event)"
    >
      <template #editRequestForOffer="{ activatorType }">
        <RequestForOfferDialog
          :activatorType="activatorType"
          :tender="tender"
        />
      </template>
      <template #specificationInfo>
        <SimpleInfoDialog title="Kravspecifikation">
          <p>
            Den digitale kravspecifikation, som består af krav fra rammeaftalen, guider dig til at specificere dit behov på en overskuelig måde.
          </p>
          <p>
            Før du begynder, anbefaler SKI, at du har lavet en opgørelse over dit indkøbsbehov. Dernæst kan du i kravspecifikationen da tilvælge de krav, der er relevante i forhold til dit indkøbsbehov.
          </p>
          <p>
            På baggrund af de valg, du fortager her, bliver der til sidst dannet en leveringskontrakt med tilhørende bilag, som angivet i rammeaftalens bilag E, Leveringskontrakt.
          </p>
        </SimpleInfoDialog>
      </template>
    </TenderMaterialTableV2>
    <v-row no-gutters>
      <v-col style="max-width: 350px !important" class="text-center">
        <img
          alt="Pil ned"
          class="ml-1"
          height="40"
          src="@/assets/arrow_green_down.svg"
        />
        <div class="ml-4">
          <v-btn
            :disabled="tender.state !== TenderStateEnum.Evaluate"
            @click="updateTab('evaluation')"
            class="primary-button"
          >
            Gå til evaluering
          </v-btn>
          <p v-if="tender.state !== TenderStateEnum.Evaluate">Skift materialets status fra 'Kladde' til 'Færdig' for at gå til
            evaluering.</p>
        </div>
      </v-col>
    </v-row>

    <SpecificationEditDialog
      v-model="showSpecificationEditor"
      :tender="tender"
      @questionnaireUpdated="updateTender($event)"
    />
    <SpecificationPreviewDialogWithoutActivator
      v-model="showSpecificationViewer"
      :tender="tender"
    />
    <LoadingSpinner :visible="isLoading" />
  </v-container>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import {
  LoadingSpinner,
  MaterialDocument,
  MaterialFileInfo,
  useStore,
  TenderMaterialTableV2,
  SimpleInfoDialog,
  RequestForOfferDialog,
  SpecificationPreviewDialogWithoutActivator,
  TenderMaterialIntroText,
  SpecificationEditDialog,
  tenderHelper,
  DeepQuestionnaire,
  TenderStateEnum,
  createFileInfo,
  Tender,
} from '@dims/components';
import { TenderData0219 } from '@/models/Tender';
import { UpdateTenderRequest0219 } from '@/models';

/** This is the tender materials page for direct allocation tenders.
 * If you are looking for the standard tender materials page for DIS look at TenderMaterial
 *
 * Presentations of documents when tender is in draft state.
 * Each of the standard files can be replaced by a custom uploaded file
 * Additional custom files can be uploaded
 * For the standard documents, link to questionnaire dialog
 * For custom documents, download.
 */
const emit = defineEmits<{
  isLoaded: [true],
  updateTab: [string]
  }>();
const { readonly = false, tender } = defineProps<{
  readonly?: boolean,
  tender: Tender }>();
const store = useStore();
const showSpecificationEditor = ref(false);
const showSpecificationViewer = ref(false);
const isLoading = ref(false);

onMounted(() => {
  emit('isLoaded', true);
});

const questionnaireMappings = computed(() => tender.agreementConfiguration.questionnaires);

// NOTE: This is currently the only agreement using direct awards
// before another agreement using direct award is created, the shored code should be consolidated.
const documentList = computed(() => {
  /*
      List of documents.

      Each line represent a document or questionnaire which corresponds to one or more files
      - Specification has one line, one artifact, and custom UI for edit/preview
      - User-uploaded files have one line corresponding to one file

    */
  let documents: MaterialDocument[] = [];
  const specificationFile = createFileInfo(
    tender,
    questionnaireMappings.value,
    'specification',
    'specification',
    'SKI kravspecifikation',
    'Egen kravspecifikation',
  );

  const specification = {
    kind: 'specification' as const,
    title: 'SKI kravspecifikation',
    label: 'Kravspecifikation',
    hasStatus: true,
    isUserUploaded: false,
    completed: tender.data.specificationDone ?? false,
    files: [specificationFile],
  };
  documents.push(specification);

  const appendices = tenderHelper.getAppendices(tender);
  const appendiceDocuments = appendices.map(
    (a) => ({
      kind: 'file' as const,
      title: 'Bilag',
      hasStatus: false,
      isUserUploaded: true,
      files: [
        {
          label: a.displayName,
          tenderArtifact: a,
          templateKey: a.templateKey,
          templateArtifactKey: a.templateArtifactKey,
          isUserUploaded: true,
        },
      ],
    } satisfies MaterialDocument),
  );
  documents = documents.concat(appendiceDocuments);
  return documents;
});

async function removeFile(file: MaterialFileInfo) {
  const { templateKey, templateArtifactKey } = file;
  if (!file.isUserUploaded) {
    throw new Error(
      `File not user-uploaded: ${templateKey} / ${templateArtifactKey}`,
    );
  }
  let data: TenderData0219 | undefined;

  isLoading.value = true;

  if (templateKey === 'specification') {
    tender.data.specificationDone = false;
    data = {
      specificationDone: false,
      usesOwnSpecification: false,
    };
  }

  if (data) {
    const update: UpdateTenderRequest0219 = {
      id: tender.id,
      data,
    };
    await store.updateTenderAction(update);
  }

  await store.deleteTenderArtifactAction({
    tender,
    templateKey,
    templateArtifactKey,
  });

  isLoading.value = false;
}

function onEdit(doc: MaterialDocument) {
  if (doc.kind === 'specification') {
    showSpecificationEditor.value = true;
  }
}

function onPreview(doc: MaterialDocument) {
  if (doc.kind === 'specification') {
    showSpecificationViewer.value = true;
  }
}

function updateTab(tabName: string) {
  emit('updateTab', tabName);
}

async function setDocumentDoneStatus(doc: MaterialDocument, completed: boolean) {
  isLoading.value = true;
  // Verify if operation is valid
  if (completed) {
    if (doc.kind === 'specification') {
      if (!(isSpecificationComplete())) {
        isLoading.value = false;
        store.setSnackbarText(
          'Kravspecifikationen er endnu ikke udfyldt',
        );
        return;
      }
    }
  }

  try {
    await store.setTenderSpecificationStatusAction(tender, completed ? 'done' : 'draft');
  } catch (e) {
    store.setSnackbarText('Kan ikke markere dokument som færdigt.');
  } finally {
    isLoading.value = false;
  }
}

function isSpecificationComplete() {
  const tenderData = tender.data as TenderData0219;
  return tenderData.variantsDone;
}

const uploadModel = [
  {
    label: 'Bilag',
    files: [
      {
        label: 'Vedhæft bilag',
        template: 'appendices' as const,
        key: '',
      },
    ],
  },
];

/* async */ function updateTender(questionnaire: DeepQuestionnaire) {
  const data = tender.data as TenderData0219;
  const p = questionnaire.questions[0];
  // TODO: syncronize data to match questionnaire
  // data.systemType = SystemTypesEnum.PartII;
  // const update: UpdateTenderRequest = {
  //   id: tender.id,
  //   data,
  // };
  // await this.store.updateTenderAction(update);

  // should update DocumentationRequested here
  // but we do not have an instance of the winning offer, so how?

  console.log(
    `Questionnaire updated ${tender.id} X ${data.systemType} X ${p?.questionId}`,
  );
}

</script>
