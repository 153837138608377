<template>
  <div>
    <AppLayout :customPages="customPages" />
  </div>
</template>

<script setup lang="ts">
import { provide, computed } from 'vue';

import {
  useStore,
  AppLayout,
  injectionKeys,
} from '@dims/components';
import { getCalculator, tenderDisplay, prefills, offerService, materialsConfig, questionnaireTabConfig, pricePointDropdownValues } from '@/services';
import componentProvider from '@/componentProvider';
import requestQuestionnaireProviderFactory from '@/components/Tender/TenderMaterial/requestQuestionnaireProviderFactory';
import agreementSpecific from './agreementSpecific';

interface Page {
  path: string;
  text: string;
}

provide(injectionKeys.componentProviderKey, componentProvider);
provide(injectionKeys.agreementSpecificKey, agreementSpecific);
provide(injectionKeys.tenderDisplayKey, tenderDisplay);
provide(injectionKeys.calculatorKey, getCalculator());
provide(injectionKeys.offerValidatorKey, offerService);
provide(injectionKeys.prefillsKey, prefills);
provide(injectionKeys.materialsConfigKey, materialsConfig);
provide(injectionKeys.questionnaireTabConfigKey, questionnaireTabConfig);
provide(injectionKeys.requestForOfferQuestionnaireKey, requestQuestionnaireProviderFactory);
provide(injectionKeys.pricePointDropDownValuesKey, pricePointDropdownValues);
const store = useStore();

const customPages = computed((): Page[] => (store.isCustomer ? [
  {
    path: '/riskEvaluationTool',
    text: 'Risikovurderingsværktøj',
  },
] : []));

</script>
