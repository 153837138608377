<template>
  <CreateTenderDialog
    v-if="store.myTendersFetched"
    :tender="tender"
    :tender-stub="tenderStub"
    :create-button-disabled="isCreateTenderButtonDisabled()"
    :createAsTemplate="createAsTemplate"
  >
    <template #create-tender-wizard-step>
      <CreateTenderWizardStep :createAsTemplate="createAsTemplate"></CreateTenderWizardStep>
    </template>
  </CreateTenderDialog>
</template>

<script setup lang="ts">
import { CreateTenderDialog, Tender, useStore } from '@dims/components';
import CreateTenderWizardStep from '@/components/create-tender/CreateTenderWizardStep.vue';
import { useStore0219 } from '@/store/store0219';
import { tenderStub as defaultTenderStub } from '@/services/utils0219';
import { SystemTypesEnum } from '@/models';

const { tender = null, createAsTemplate = false } = defineProps<{
  /** Only when the create/clone button is on an existing tender */
  tender?: Tender | null,
  createAsTemplate?: boolean,
}>();
const store = useStore();
const store0219 = useStore0219();

function isCreateTenderButtonDisabled() {
  return !store0219.draftTender
      || !store0219.draftTender.description
      || !store0219.draftTender.data.systemType
      || (!store0219.draftTender.data.serviceArea && store0219.draftTender.data.systemType !== SystemTypesEnum.PartIII);
}

const tenderStub = { ...defaultTenderStub, agreementName: store.agreementId };

</script>
